/* Reset */
* {
    margin: 0;
    padding: 0;
}

.App {
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    margin-top: 1%;
}



/* START Importado da v1 */
@media print {
    body{
        width: 21cm;
        height: 25.7cm;
        margin: 30mm 45mm 30mm 0mm; 
        /* change the margins as you want them to be. */
        font-family: Arial, Helvetica, sans-serif;
   } 
   @page {
    margin-top: 0;
    margin-bottom: 0;
}
   @page :footer {
    display: none
    }

    @page :header {
        display: none
    }

    .row.adicionar-item {
        display: none;
    }
    .remover-item {
        display: none;
    }
    .section-descricao-item b {
        display: none;
    }
    textarea {
        border: 0;
        resize: none;
    }

/* Remove os inputs quando imprime no Firefox */
input[type=number] {
    -moz-appearance: textfield;
  }
}

.avisos {
    text-align: center;
    font-size: 12px;
}

textarea, input { outline: none; border: 0; margin:0;padding: 0; resize:none;}

body {
    margin-top: 3%;
}

.row {
    margin-left: 0;
    margin-right: 0;
}
.container {
    padding-right: 0;
    padding-left: 0;
}

.row .titulo {
    text-transform: uppercase;
    text-align: center;
    border: 3px solid #000;
}
.row .titulo h1 {
    font-size: 14pt;
    letter-spacing: 2px;
    padding: 0;
    padding-top: 10px;
}
.remetente_destinatario {
    margin-top:6px;
    
}
.remetente, .destinatario {
    border: 3px solid #000;
    padding: 0;
}
.remetente_destinatario .nome, .remetente_destinatario .endereco, .remetente_destinatario .cidade, .remetente_destinatario .cep {
    padding-left: 2px;
}
.cep div, .cpf_cnpj div {
    border-bottom: 0!important;
}
.endereco textarea {
    width: 90%;
}
.remetente_destinatario .uf, .remetente_destinatario .cpf_cnpj {
    border-left: 1px solid #000;
    padding-left: 2px;
}

.destinatario div,.remetente div {
    padding: 0;
}
.remetente h2, .destinatario h2 {
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #000;
    font-size: 10pt;
    padding: 5px;
    letter-spacing: 4px;
    font-weight: bold;
}
.remetente_destinatario span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10pt;
}
.remetente_destinatario span.editavel {
    font-weight: normal;
    text-transform: capitalize;
    padding-left: 5px;
}
.destinatario .row, .remetente .row {
    border-bottom: 1px solid #000;
    margin-right: 0;
    margin-left: 0;
}
.identificacao_dos_bens {
    border: 3px solid #000;
    margin-top: 10px;
    padding: 0;
}
.identificacao_dos_bens .col-sm {
    padding: 0;
}
.identificacao_dos_bens h2 {
    border-bottom: 3px solid #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 12pt;
    font-weight: bold;
    padding: 10px 0;
    margin: 0;
}
.row.identificacao_cabecalho {
    margin: 0;
}
.row.identificacao_cabecalho {
    border-bottom: 1px solid #000;
    text-transform: uppercase;
    text-align: center;
    font-size: 8pt;
}
.row.identificacao_cabecalho div {
    padding: 5px 0;
    border-right: 1px solid #000;
}
.row.identificacao_cabecalho div:last-child {
    border-right: none;
}
.row.dados_item {
    margin: 0;
}
.row.totais {
    margin: 0;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
}

.row.totais div:first-child {
    background-color: #ccc;
    text-align: right;
    text-transform: uppercase;
    border-right: 1px solid #000;
    font-size: 10pt;
    padding-top: 2px;
    padding-bottom: 2px;
}
.row.totais div:nth-child(2) {
    border-right: 1px solid #000;
    text-align: center;
}
.row.totais div:last-child {
    text-align:center;
}

.row.peso_total {
    margin: 0;
    border-bottom: 1px solid #000;
}
.row.peso_total div:first-child {
    background-color: #ccc;
    text-align: right;
    text-transform: uppercase;
    border-right: 1px solid #000;
    font-size: 10pt;
    padding-top: 2px;
    padding-bottom: 2px;
}
.row.peso_total div:nth-child(2) {
    border-right: 1px solid #000;
    text-align: center;
}
.row.peso_total div:last-child {
    text-align:center;
}
.container.declaracao_final {
    padding: 0;
    margin-top: 10px;
    border: 3px solid #000;
}
.container.declaracao_final .col-sm {
    padding-left: 0;
    padding-right: 0;
}
.container.declaracao_final h3 {
    border-bottom: 2px solid #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 12pt;
    font-weight: bold;
    padding: 10px 0;
    margin: 0;
    margin-bottom: 10px;
}
.container.declaracao_final p {
    padding: 5px 5px;
    margin: 0;
    font-size: 10pt;
    line-height: 110%;
}
.container.declaracao_final p::first-line {
    text-indent: 40px;
}

.container.observacao {
    margin-top: 10px;
    border: 3px solid #000;
    padding: 10px 0px;
}
.container.observacao h4 {
    text-transform: uppercase;
    font-size: 12pt;
    letter-spacing: 2px;
    font-weight: bold;
}
.container.observacao p {
    font-size: 10pt;
    line-height: 100%;
}
.row.assinaturas {
    margin-top: 10px;
}
.row.assinaturas div {
    padding:2px;
}
.row.assinaturas input {
    border-bottom: 1px solid #000;
    width: 80%;
    float: left;
}
.row.row.subassinaturas {
    margin-bottom: 10px;
}
.row.assinaturas span {
    width: 10%;
    float: left;
}
#assinatura_cidade {
    width: 90%;
}
#assinatura_dia {
    width: 50%;
}
#assinatura_assinatura {
    width: 98%;;
}
.row.subassinaturas {
    text-align: center;
    font-size: 8pt;
}
#uf_remetente, #uf_destinatario {
    text-transform: uppercase;
}
/* #uf_remetente, #cep_remetente, #cpf-cnpj_remetente {
    max-width: 60%;
}
#uf_destinatario, #cep_destinatario, #cpf-cnpj_desintatario {
    max-width: 60%;
} */
.destinatario input, .destinatario textarea, .remetente input, .remetente textarea {
    font-size: 11pt;
}
.item_valor {
    max-width: 70%!important;
}
div.row.dados_item , .row.totais div {
    padding:0;
    border: 0;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}
.row.totais div:first-child {
    padding-right: 15px;
}
.row.dados_item input, .row.totais input {
    text-align: center;
    width: 100%;
}
.row.adicionar_itens {
    padding: 10px;
    text-align: center;
}
.btn-excluir {
    color: red;
    font-size: 10px;
    text-decoration: none;
}
/* END importado da v1 */


.adicionar-item {
    text-align: center;
    padding: 10px;
}
.adicionar-item button {
    padding-right: 20px;
    padding-left: 10px;
    margin-right: 25px;
}
.remover-item {
    border: 0!important;
    padding: 0!important;
    float: right;
}
.descricao-item {
    padding: 0px;
    border-left: 1px solid black;
}
.quantidade-item {
    border-left: 1px solid black;
    padding: 0!important;
}
.valor-item {
    border-left: 1px solid black;
    padding: 0!important;
}
.fake-input-descricao {
    padding: 0!important;
    margin: 0!important;
    float: left;
    overflow: hidden;
}


/* Ajustes no Input */

.react-numeric-input {
    max-width: 100%;
}
input.input-quantidade {
    max-width: 100%;
}
input.input-valor {
    max-width: 100%;
}

.totais-valor-total, .totais-qtd-total {
    text-align: center;
}
.totais-valor-total::before {
   content: 'R$ ';
}
.react-numeric-input input {
 border: 0!important;   
}
.coluna-indice span{
    text-align: center;
    width: 100%;
    height: 100%;
    float: left;
    padding-top: 2px;
}
.section-descricao-item input {
    font-size: 14px!important;
}

.floatright {
    float: right!important;
}
.floatleft {
    float: left!important;
}
.cabecalho textarea, .cabecalho input {
    width: 100%;
    overflow: hidden;
}